import { Checkbox, DatePicker, Form, Input, Radio, Space } from 'antd'
import React, { Fragment } from 'react'

import moment from 'moment'
import SimpleTable from '../simple-table'
import useTranslate from '../../hooks/use-translate'
import HeadImage from '../head-image'
import useScreen from '../../hooks/use-screen'
import { useRecoilValue } from 'recoil'
import { clientState } from 'src/state'

const mapComponents = ({
  key = '',
  field = {},
  storedValue = {},
  updateState = (_: any) => { },
  isSaving = false,
  t = (_: string) => { },
  form,
  formInstance,
}: any): any => {
  switch (field.component) {
    case 'DatePicker':
      return (
        <Form.Item
          name={key}
          key={key}
          label={t(field.label)}
          rules={field?.rules}
          valuePropName={'date'}
        >
          <DatePicker
            format={'YYYY/MM/DD'}
            size="large"
            onChange={(date: any) => {
              updateState((currentState: any) => ({
                ...currentState,
                [key]: moment(date).endOf('day'),
              }))
            }}
          />
        </Form.Item>
      )
    case 'TextArea': {
      return (
        <Form.Item
          key={key}
          name={key}
          label={t(field.label)}
          className="formItem"
          rules={field?.rules}
        >
          <Input.TextArea
            size="large"
            rows={field.rows || 5}
            disabled={isSaving}
          />
        </Form.Item>
      )
    }
    case 'SimpleTable': {
      return <SimpleTable key={key} data={field.config} isSaving={isSaving} />
    }
    case 'HeadImage': {
      return Boolean(field?.src?.length) ? (
        <HeadImage key={key} src={field.src} />
      ) : null
    }
    case 'TextInput': {
      return (
        <Form.Item
          key={key}
          name={key}
          className="formItem"
          label={t(field.label)}
          rules={field.rules}
        >
          <Input size="large" autoFocus={field.autoFocus} disabled={isSaving} />
        </Form.Item>
      )
    }
    case 'CheckBox': {
      return (
        <Form.Item
          key={key}
          name={key}
          className="formItem"
          rules={
            field.dependencies
              ? [
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const isRequired = getFieldValue(field.dependencies[0])
                    if (!value && isRequired === field.dependenciesValue) {
                      return Promise.reject(field.customErrorMessage)
                    }
                    return Promise.resolve()
                  },
                }),
              ]
              : field.rules
          }
          valuePropName="checked"
          dependencies={field.dependencies}
        >
          <Checkbox disabled={isSaving}>{t(field.label)}</Checkbox>
        </Form.Item>
      )
    }
    case 'RadioButtonsWithMore': {
      return (
        <div key={`RadioButtonsWithMore_${key}`} style={{ marginBottom: 24 }}>
          <Form.Item
            key={key}
            name={key}
            label={t(field.label)}
            className="formItem"
            rules={field?.rules}
            style={{ marginBottom: 10 }}
          >
            <Radio.Group
              onChange={(e: any) => {
                if (storedValue?.[key] === field?.moreOn) {
                  updateState((currentState: any) => ({
                    ...currentState,
                    [key]: e.target.value,
                  }))
                } else {
                  updateState((currentState: any) => ({
                    ...currentState,
                    [key]: e.target.value,
                    [`${key}_more`]: '',
                  }))
                  formInstance.setFieldsValue({
                    [`${key}_more`]: '',
                  })
                }
              }}
              style={{ width: '100%' }}
            >
              <Space direction="horizontal" style={{ width: '100%' }}>
                {field?.options?.map((option: string) => {
                  return (
                    <div key={option}>
                      <Radio value={option}>{option}</Radio>
                    </div>
                  )
                })}
              </Space>
            </Radio.Group>
          </Form.Item>
          {storedValue?.[key] === field?.moreOn ? (
            <div>
              <Form.Item
                key={`${key}_more`}
                name={`${key}_more`}
                className="formItem"
                rules={[
                  {
                    required: storedValue?.[key] === field?.moreOn,
                    message: field?.customErrorMessage,
                  },
                ]}
                dependencies={field.dependencies}
              >
                <Input
                  placeholder={t(field.moreLabel)}
                  size="large"
                  autoFocus={field.autoFocus}
                  disabled={isSaving || storedValue?.[key] !== field?.moreOn}
                />
              </Form.Item>
              {field?.checkbox ? (
                <Form.Item
                  key={`${key}_checkbox`}
                  name={`${key}_checkbox`}
                  className="formItem"
                  rules={[
                    {
                      required: storedValue?.[key] === field?.moreOn,
                      message: field?.customErrorMessage,
                    },
                  ]}
                  dependencies={field.dependencies}
                  valuePropName="checked"
                >
                  <Checkbox>{field?.checkbox}</Checkbox>
                </Form.Item>
              ) : null}
            </div>
          ) : null}
        </div>
      )
    }
    default:
      return null
  }
}

const FormComposer = ({
  form = {},
  formInstance = {},
  // storedValue = {},
  // setValue = () => {},
  isSaving,
}: any) => {
  const client = useRecoilValue(clientState)

  const { t } = useTranslate({
    clientData: {
      ...client,
    },
  })

  const { storedValue, updateState } = useScreen()

  if (!form) {
    return null
  }

  return (
    <Fragment>
      {Object.keys(form).map((key: string) =>
        mapComponents({
          key,
          field: form[key],
          storedValue,
          updateState,
          isSaving,
          t,
          form,
          formInstance,
        }),
      )}
    </Fragment>
  )
}

export default FormComposer
