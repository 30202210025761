/** @jsx jsx */
import { Global, jsx } from '@emotion/core'
import { FunctionComponent, useEffect, useState } from 'react'
import { Router, globalHistory } from '@reach/router'
import NotFound from './screens/not-found'
import * as theme from './theme'

import Route from './Route'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  activeRoutesSelector,
  appState,
  configState,
  screenActionState,
  screenErrorState,
} from './state'
import ScreenEmpty from './screens/empty'
import Container from './components/container'
import Page from './components/page'
import ScreenReferenceCode from './screens/reference-code'
import ScreenThankYou from './screens/thank-you'
import ScreenCustomerDetails from './screens/customer-details'
import ScreenAlerisReferral from './screens/aleris-referral'
import ScreenBook from './screens/book'
import ScreenConfirm from './screens/confirm'
import GDPRConsent from './components/gdpr-consent'
import ScreenCancel from './screens/cancel'
import ScreenCancelConfirmation from './screens/cancel-confirmation'
import ScreenCancelAleris from './screens/cancel-aleris'
import { getClientConfig } from './api/backend-client'
import LoadingSpinner from './components/loading-spinner'
import useScreen from './hooks/use-screen'
import ScreenDynamic from './screens/screen-dynamic'
import { useMountEffect } from './hooks/use-mount-effect'
import { GlobalStyle } from './App.style'

declare const window: any

const mapComponents = (component: string): FunctionComponent => {
  switch (component) {
    case 'ScreenReferenceCode':
      return ScreenReferenceCode
    case 'ScreenThankYou':
      return ScreenThankYou
    case 'ScreenCustomerDetails':
      return ScreenCustomerDetails
    case 'ScreenAlerisReferral':
      return ScreenAlerisReferral
    case 'ScreenBook':
      return ScreenBook
    case 'ScreenConfirm':
      return ScreenConfirm
    case 'ScreenCancel':
      return ScreenCancel
    case 'ScreenCancelConfirmation':
      return ScreenCancelConfirmation
    case 'ScreenCancelAleris':
      return ScreenCancelAleris
    case 'ScreenDynamic':
      return ScreenDynamic
    default:
      return ScreenEmpty
  }
}

const fetchConfig = async () => {
  // get clientId from url where url can be demo-clinic.kinc.app
  const url = window.location.hostname
  let clientId = url.split('.')[0]

  if (clientId === 'localhost') {
    clientId = 'x-vision-ogonklinik'
  }

  const client = await getClientConfig(clientId)
  // const clientId = client?.clientId

  window.kindClientConfig = client

  if (!clientId) {
    throw new Error('No client found')
  }

  return client
}

function WebApp() {
  const setScreenAction = useSetRecoilState(screenActionState)
  const activeRoutes = useRecoilValue(activeRoutesSelector)
  const setScreenError = useSetRecoilState(screenErrorState)

  const updateState = useSetRecoilState(appState)
  const [config, updateConfig] = useRecoilState(configState)

  const [loading, setLoading] = useState(true)

  const { initialFormData, pathname } = useScreen()

  useEffect(() => {
    const removeListener = globalHistory.listen(({ action }) => {
      setScreenAction('idle')
      setScreenError('')
    })
    return () => {
      removeListener()
    }
  }, [setScreenAction, setScreenError])

  useMountEffect(() => {
    fetchConfig()
      .then(config => {
        const { appName, testType, initalValues } = config
        document.title = appName

        updateConfig(config)

        if (pathname === '/') {
          updateState(currentState => ({
            ...initalValues,
            ...initialFormData,
            ...currentState,
            testType,
          }))
        }

        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  })

  if (loading) {
    return <LoadingSpinner />
  }

  if (!config.appName) {
    return <NotFound />
  }

  return (
    <Container>
      <Global
        styles={GlobalStyle(
          config.theme ?? {
            ...theme,
          },
        )}
      />

      <Page>
        <GDPRConsent />
        <Router primary={false}>
          {Object.keys(activeRoutes).map((routePath: string) => {
            return (
              <Route
                key={routePath}
                component={mapComponents(activeRoutes?.[routePath]?.component)}
                path={routePath}
              />
            )
          })}
          <Route component={NotFound} default />
        </Router>
      </Page>
    </Container>
  )
}

export default WebApp
