import {navigate, useLocation} from '@reach/router'
import {Form, message} from 'antd'
import moment, {Moment} from 'moment'
import {useEffect, useRef, useState} from 'react'
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil'
import {fetchOrganizations, getSlots} from '../api/backend-client'
import {
  AppState,
  appState,
  clientState,
  configState,
  currentPageState,
  nextRouteSelector,
  screenActionState,
  screenErrorState,
} from '../state'

import Personnummer from 'personnummer'
import useTranslate from './use-translate'
import {createBooking} from '../api/booking-client'

message.config({
  maxCount: 1,
})

const initialFormData: Partial<AppState> = {
  phoneType: 'MOBILE',
  otherInformation: 'no',
  countryCode: '+46',
  otherInformationValue: [],
  referralType: 'form',
  skipBooking: false,
  withoutAppointment: false,
  referenceCode: window.localStorage.getItem('referenceCode') || '',
}

const removeLeadingZeroFromString = (str = '') => {
  return str.replace(/\b0+/g, '')
}

const getFirstAvailableTime = (): Moment => {
  const theoreticalStartingTime = moment().add(2, 'hours')
  return theoreticalStartingTime
}

const firstAvailableTime = getFirstAvailableTime()

const firstAvailableDate = moment(firstAvailableTime).startOf('day')
const lastAvailableDate = moment(firstAvailableDate)
  .add(2, 'month')
  .startOf('day')

const formatNIN = (nin: string) => {
  if (Personnummer.valid(nin)) {
    return Personnummer.parse(nin).format(true)
  }

  return nin
}

function useScreen() {
  const location = useLocation()
  const {pathname} = location

  const [form] = Form.useForm()

  const client = useRecoilValue(clientState)

  const {t} = useTranslate({
    clientData: {
      ...client,
    },
  })

  const [screenAction, setScreenAction] = useRecoilState(screenActionState)
  const setScreenError = useSetRecoilState(screenErrorState)

  const nextRoutePath = useRecoilValue(nextRouteSelector(pathname))

  const config = useRecoilValue(configState)

  const currentPage = useRecoilValue(currentPageState(pathname))

  const [storedValue, updateState] = useRecoilState(appState)

  const [, forceUpdate] = useState<any>()

  const isSaving = screenAction === 'saving'

  const allowNextStep = useRef(false)

  const setDefaultCityAndOrganization = (
    referenceCode: string,
    promoCode: string = '',
  ) => {
    return new Promise((resolve, reject) => {
      fetchOrganizations(referenceCode)
        .then((response: any) => {
          const orgs = response?.orgs
          if (orgs.length === 1) {
            getSlots(
              orgs?.[0]?.uuid,
              firstAvailableTime.toISOString(),
              lastAvailableDate.toISOString(),
              referenceCode,
              promoCode,
            )
              .then((response: any) => {
                if (response.slots.length === 0) {
                  const values: Partial<AppState> = {
                    organizationId: orgs[0].uuid,
                    organizationName: orgs[0].name,
                    cityId: orgs[0].city,
                    skipBooking: true,
                    slotsAvailable: false,
                  }
                  updateState(currentState => ({
                    ...currentState,
                    ...values,
                  }))
                  resolve(true)
                } else {
                  const values: Partial<AppState> = {
                    organizationId: null,
                    organizationName: null,
                    cityId: null,
                    skipBooking: false,
                    slotsAvailable: true,
                  }
                  updateState(currentState => ({
                    ...currentState,
                    ...values,
                  }))
                }
              })
              .catch((error: Error) => {
                // pass
                reject(error.message)
              })
          } else {
            const values: Partial<AppState> = {
              organizationId: orgs[0].uuid,
              organizationName: orgs[0].name,
              cityId: orgs[0].city,
              skipBooking: false,
              slotsAvailable: false,
            }
            updateState(currentState => ({
              ...currentState,
              ...values,
            }))
            resolve(true)
          }
        })
        .catch((error: Error) => {
          // pass
          reject(error.message)
        })
    })
  }

  const setFullMobileNumber = (countryCode: string, phoneNumber: string) => {
    if (phoneNumber) {
      return `${countryCode}${removeLeadingZeroFromString(phoneNumber)}`
    }
    return phoneNumber
  }

  const handleNext = async (form: any) => {
    if (form) {
      allowNextStep.current = false
      setScreenAction('saving')

      form
        .validateFields()
        .then(async (values: AppState) => {
          if (values.referenceCode) {
            await setDefaultCityAndOrganization(values.referenceCode)

            // NOTE: Save reference code for later user after user reset form
            window.localStorage.setItem('referenceCode', values.referenceCode)
          }

          if (values.phoneNumber) {
            values.mobileNumber = setFullMobileNumber(
              storedValue.countryCode,
              values.phoneNumber,
            )
          }

          if (values.personalNumber) {
            const personalNumber = formatNIN(values.personalNumber)
            values.personalNumber = personalNumber
          }

          updateState(currentState => ({
            ...currentState,
            ...values,
          }))

          const {
            referenceCode,
            mobileNumber,
            personalNumber,
            firstName,
            lastName,
            organizationId,
            startTime,
            promoCode,
            files,
            recaptchaToken,
            skipBooking,
            withoutAppointment,
            slotsAvailable,
          } = storedValue

          allowNextStep.current = true

          if (pathname === '/book') {
            if (
              !skipBooking &&
              !withoutAppointment &&
              slotsAvailable &&
              !startTime
            ) {
              allowNextStep.current = false
              setScreenError('NO_START_TIME_SELECTED')
            }
          }

          if (pathname === '/confirm') {
            allowNextStep.current = false

            await createBooking({
              testType: config.testType,
              referenceCode: referenceCode,
              phoneNumber: mobileNumber,
              nin: personalNumber,
              firstName: firstName,
              lastName: lastName,
              organizationId: organizationId || '',
              startTime: startTime,
              promoCode: promoCode,
              files: (files || []).map((file: any) => file.fileId),
              form: {
                ...storedValue,
                docs: [{...config.pages?.['/referral']}],
              },
              recaptchaToken,
            })
              .then(() => {
                allowNextStep.current = true
                window.localStorage.removeItem('kindBookingAppState')
              })
              .catch((error: any) => {
                allowNextStep.current = false
                setScreenError(t('GENERIC_CONFIRM_ERROR'))
                setScreenAction('idle')
                throw error
              })
          }

          if (allowNextStep.current) {
            navigate(nextRoutePath)
          }
        })
        .catch((error: Error) => {
          setScreenAction('idle')
          message.error({
            content: 'Fyll i alla obligatoriska fält för att fortsätta.',
            style: {
              marginTop: 'calc(100vh - 150px)',
            },
          })
        })
    } else {
      allowNextStep.current = true
    }
  }

  useEffect(() => {
    forceUpdate({})
  }, [])

  return {
    pathname,
    form,
    storedValue,
    isSaving,
    currentPage,
    initialFormData,
    client: config,
    handleNext,
    setScreenError,
    updateState,
  }
}

export default useScreen
